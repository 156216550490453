<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useListTable from '@/comp-functions/useListTable';

export default {
  page: {
    title: "Port List",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  setup () {
     const { 
      isBusy,
      itemLists,
      perPage,
      perPageDefault,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists } = useListTable({ url: 'master/port' })

    const tableColumns = [
      //this.$t('fields.port.name')
      { key: "prt_name", label: 'Name', sortable: true },
      { key: "prt_initial", label: "Initial", tdClass: 'text-center', sortable: true },
      { key: "prt_locationname", label: "Location", sortable: true },
     { key: "prt_timezoneid", label: "Time Zone", tdClass: 'text-center', sortable: true },
    ]
    
    const columnToBeFilter = [
      'prt_name',
      'prt_initial',
      'prt_locationname',
      'prt_timezoneid'
    ]
    return {
      tableColumns,
      columnToBeFilter,
       isBusy,
      itemLists,
      perPage,
      perPageDefault,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    }
  },
  data() {
    return {
      title: "Port List",
      items: [],
      filterOn: []
    };
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalList = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-button href="/masterdata/port/add" variant="light">
              <i class="ri-add-line align-middle mr-2"></i> {{ $t('global.button.add') }}
            </b-button> -->
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="searchQuery"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :fields="tableColumns"
                :items="itemLists"
                :per-page="perPage"
                :current-page="currentPage"
                :filter="searchQuery"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                responsive
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                :busy="isBusy"
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
                class="position-relative"
              >
              <template #cell(prt_timezoneid)="data">
                  {{ data.item.timeZone.descr_en }}
                </template>
                </b-table>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center col-md-12">
                   Showing
                    <b-form-select class="col-sm-5 col-md-2" v-model="perPage" size="sm" :options="perPageOptions"></b-form-select>{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
                  </label>
                </div>
              </div>

              <div class="col-md-6 col-xs-12">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalList" :per-page="perPage" ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>